<template>
  <div>
    <div class="container">
      <div class="tabs">
        <div
          class="tab unselect"
          v-for="(tab, index) in tabs"
          :key="`tab-${index}`"
          @click="clickTab(tab.value)"
          :class="{ active: selectedStatus === tab.value }"
        >
          {{ tab.label }}
        </div>
      </div>
      <div class="body4 sub count-wrapper">전체 {{ itemFiltered.count }}개</div>
      <div class="template-inquiry-wapper">
        <div class="template-inquiry-item" v-for="(inquiry, idx) in list" :key="'menu-' + idx">
          <div class="template-inquiry-parnter-info">
            <img class="prod-img" :src="inquiry.img" />
            <div class="flex-column">
              <div class="body5 sub3">{{ inquiry.template_partner }}</div>
              <div class="body2-bold main">{{ inquiry.product }}</div>
              <div class="body4 main">{{ priceFormat(inquiry.price) }}</div>
            </div>
          </div>
          <div class="line"></div>
          <div @click="toggleInquiry(inquiry)">
            <!-- 질문 -->
            <div class="flex-between">
              <div>
                <div class="flex-align">
                  <span
                    class="body7 gray3"
                    :class="{ answered: inquiry.answer_content, 'not-answered': !inquiry.answer_content }"
                  >
                    {{ inquiry.answer_content ? '답변완료' : '미답변' }}</span
                  >
                  <span class="body6 sub3 margin-left-8">{{ datesFormat(inquiry.created_time, 'date_3') }}</span>
                  <span class="gray1 margin-left-8 margin-right-8">|</span>
                  <span class="body6 sub3">{{ authorFormat(inquiry.author) }} </span>
                </div>
              </div>
            </div>
            <!-- 답변 -->
            <div class="inquiry-answer-wrapper margin-top-16">
              <div class="flex cursor-pointer">
                <div class="body4-bold primary">Q</div>
                <div class="subtitle7 sub margin-left-8 ellipsis-1" style="width:970px">
                  {{ inquiry.title ? inquiry.title : '질문' }}
                </div>
              </div>
              <div class="body4 sub margin-top-8">{{ inquiry.question_content }}</div>
              <div v-if="inquiry.answer_content">
                <div class="flex-align margin-top-16" style="gap:8px">
                  <div class="body4-bold primary">A</div>
                  <div class="subtitle7 sub">{{ inquiry.answerer }} 답변</div>
                  <div class="body6 sub3">{{ datesFormat(inquiry.answered_time, 'date_3') }}</div>
                </div>
                <div class="body4 sub">{{ inquiry.answer_content }}</div>
              </div>
            </div>
          </div>
          <!-- 수정 -->
          <div v-if="user.user_id === inquiry.author_id">
            <!-- <button
                  class="body5-medium button is-primary-light"
                  style="width:66px;height:36px"
                  @click="modifyItem(inquiry, 'pc')"
                >
                  수정
                </button> -->
            <button
              class="body4-medium button is-gray"
              style="width:74px;height:44px"
              @click="deleteItem(inquiry, isMobile ? 'mobile' : 'pc')"
            >
              삭제
            </button>
          </div>
          <div v-if="user.partner_id && !inquiry.answer_content">
            <button
              class="body4-medium button is-primary"
              style="width:74px;height:44px"
              @click="$router.push(`/partner_center/user_temp_inquiry_detail?id=${inquiry.id}`)"
            >
              답변
            </button>
          </div>
          <div v-else>
            <button
              class="body4-medium button is-gray"
              style="width:74px;height:44px"
              @click="$router.push(`/partner_center/user_temp_inquiry_detail?id=${inquiry.id}`)"
            >
              수정
            </button>
          </div>
        </div>
      </div>
      <lp-paginate
        :filtered="itemFiltered"
        @getData="getData"
        class="padding-top-80"
        v-show="list.length > 0"
      ></lp-paginate>
      <div v-if="list && list.length === 0" class="empty-view" style="padding-top:40px">신청내역이 없습니다</div>
    </div>

    <!-- 삭제 모달 -->

    <div class="inquiry-reg-modal-pc">
      <sweet-modal overlay-theme="dark" ref="inquiryDeleteModal" :width="400" :hide-close-button="true">
        <div class="body1" style="padding:28px 0">
          삭제하시겠습니까?
        </div>
        <div class="flex-between body2-medium" style="padding:16px 16px 0">
          <button class="button" style="width:50%;height:48px;" @click="$refs.inquiryDeleteModal.close()">취소</button>
          <button class="button is-primary margin-left-16" style="width:50%;height:48px;" @click="deleteData('pc')">
            삭제하기
          </button>
        </div>
      </sweet-modal>
    </div>

    <div class="inquiry-delete-modal-mobile">
      <sweet-modal overlay-theme="dark" ref="inquiryDeleteModalMobile" width="343px" :hide-close-button="true">
        <div class="body4">
          삭제하시겠습니까?
        </div>
        <div class="flex-between body5-medium" style="padding:16px 16px 0;margin-top:22px">
          <button class="button" style="width:50%;height:36px;" @click="$refs.inquiryDeleteModalMobile.close()">
            취소
          </button>
          <button class="button is-primary margin-left-16" style="width:50%;height:36px;" @click="deleteData('mobile')">
            삭제하기
          </button>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>

<script>
import LpPaginate from '../component/LpPaginate';
import PartnerAPIMixin from '../../mixins/PartnerAPIMixin';
import { SweetModal } from 'sweet-modal-vue';
export default {
  name: 'UserTemplateInquiry',
  components: { LpPaginate, SweetModal },
  mixins: [PartnerAPIMixin],
  data() {
    return {
      tabs: [
        {
          label: '전체',
          value: '',
        },
        {
          label: '미답변',
          value: true,
        },
        {
          label: '답변완료',
          value: false,
        },
      ],
      list: [],
      page: {
        cur_page: 1,
        total_page: 1,
      },
      itemFiltered: {
        cur_page: 1,
        page_num: 1,
        total_page: 1,
        page_length: 9,
        category: 69,
      },
      selectedItem: null,
      selectedStatus: '',
    };
  },
  created() {
    this.loginRequired();
    this.getData();
  },

  methods: {
    getData() {
      let params = this.cloneItem(this.itemFiltered);
      if (this.selectedStatus && this.selectedStatus !== '') {
        params.is_answered = this.selectedStatus;
      }
      if (this.itemFiltered.cur_page > this.itemFiltered.total_page) {
        return;
      }
      this.request.partner
        .get('user_product/inquiry', {
          params: params,
        })
        .then((res) => {
          this.list = res.data.data;
          this.itemFiltered.page_length = res.data.page_length;
          this.itemFiltered.cur_page = res.data.cur_page;
          this.itemFiltered.total_page = res.data.total_page;
          this.itemFiltered.count = res.data.count;
        });
    },
    clickTab(value) {
      this.selectedStatus = value;
      this.itemFiltered.cur_page = 1;
      this.getData();
    },
    clickInquiry(inquiry) {
      if (inquiry.answer_content) inquiry.selected ? (inquiry.selected = false) : (inquiry.selected = true);
    },
    authorFormat(author) {
      if (author.length > 2) {
        return author[0] + '*'.repeat(author.length - 2) + author[author.length - 1];
      } else if (author.length === 2) {
        return author[0] + '*';
      } else {
        return author;
      }
    },
    deleteItem(inquiry, device) {
      this.selectedItem = inquiry;
      if (device === 'pc') {
        this.$refs.inquiryDeleteModal.open();
      } else {
        this.$refs.inquiryDeleteModalMobile.open();
      }
    },
    deleteData(device) {
      this.request.user
        .delete(`product/${this.selectedItem.product_id}/inquiry/${this.selectedItem.id}`)
        .then((res) => {
          if (res.status === 200) {
            this.toast('문의가 삭제되었습니다.');
            if (device === 'pc') {
              this.$refs.inquiryDeleteModal.close();
            } else {
              this.$refs.inquiryDeleteModalMobile.close();
            }
            this.getData();
          }
        });
    },
    toggleInquiry(inquiry) {
      this.$set(inquiry, 'isOpen', !inquiry.isOpen);
    },
    priceFormat(price) {
      let result = this.currency(price.price);
      if (price.won_type === 1) {
        result += '~';
      } else if (price.won_type === 2) {
        result += ' 초반대';
      } else if (price.won_type === 3) {
        result += ' 후반대';
      }
      return result;
    },
    clickItem(item) {
      this.routerPush(`/template_inquiry_detail?id=${item.id}`);
    },
    contentStatus(menu) {
      return {
        0: '문의내용 확인중',
        1: '파일 등록완료',
      }[menu.status];
    },
  },
};
</script>

<style lang="stylus" scoped>
@import'~assets/css/lp_main'

.template-inquiry-wapper
  display flex
  flex-direction column
  gap 24px

.tabs
  display flex
  gap 8px
  margin-bottom 24px
  .tab
    padding 7px 14px
    border-radius 50px
    border 1px solid $gray
    background-color white
    font-size 14px
    font-weight 500
    &.active
      background-color $main
      color white

.template-inquiry-item
  display flex
  flex-direction column
  gap 12px
  padding 24px
  border-radius 12px
  background-color #fff

.template-inquiry-parnter-info
  display grid
  grid-template-columns 54px 1fr
  gap 12px
  align-items center

.prod-img
  width 54px
  height 68px
  border-radius 5px
  object-fit cover
  border 1px solid #EEE;

.count-wrapper
  margin-bottom 16px

.line
  width 100%
  height 1px
  background-color #EEE

.answered
.not-answered
  padding 3px 8px
  border-radius 4px

.answered
  background-color #FF6600
  color #FFF

.not-answered
  background-color #FFF0E5
  color $primary


@media (max-width:425px)
  .template-inquiry-item
    padding 16px
</style>
<style lang="stylus">
.inquiry-delete-modal-mobile .sweet-modal.is-mobile-fullscreen
.inquiry-reg-modal-mobile .sweet-modal.is-mobile-fullscreen
  top 50% !important
  left 50% !important
  transform translate(-50%, -50%) !important
.inquiry-reg-modal-mobile .sweet-modal.is-mobile-fullscreen
  height 512px
.inquiry-delete-modal-mobile .sweet-modal.is-mobile-fullscreen
  height 182px

.inquiry-reg-modal-mobile .sweet-content
  padding-top 24px !important

.inquiry-delete-modal-mobile .sweet-content
  padding-top 48px !important

.inquiry-delete-modal-pc .sweet-content
.inquiry-reg-modal-pc .sweet-content
  padding-top 32px !important

.inquiry-delete-modal-mobile .sweet-content
  padding-bottom 12px !important
</style>
